import React from "react";

function SvgCaretUp({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        font-size: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.847 7.392A1.122 1.122 0 0010 7c-.322 0-.63.142-.847.392L5.15 11.999a.62.62 0 00-.1.645.572.572 0 00.523.356h8.856c.226 0 .431-.14.523-.356a.62.62 0 00-.1-.645l-4.004-4.607z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgCaretUp;
